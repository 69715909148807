import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { Drawer, Form, Button, Row, Col, Alert } from "antd";
import { showSuccessMessage, showDangerMessage } from "../../../utils";
import { saveHVCConfiguration, getProject } from "../../../APIClient";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

const HVC_CONFIG_TYPES = {
  spouse: "Spouse",
  other_family: "Other family",
  staff: "Staff",
  close_association_member: "Close association member",
  close_coworker: "Close coworker",
  top_party_leader: "Top party leader",
  top_media_member: "Top media member",
  friend: "Friend",
};

const DEFAULT_HVC_CONFIG = {
  spouse: false,
  other_family: false,
  staff: false,
  close_association_member: false,
  close_coworker: false,
  top_party_leader: false,
  top_media_member: false,
  friend: false,
};

const HVCConfigDrawer = ({ isVisible, setIsVisible }) => {
  const [form] = useForm();
  const project = useSelector((state) => state.project);
  const project_id = project?.id;
  const [btnDisabled, setBtndisabled] = useState(true);
  const [hvcConfig, setHVCConfig] = useState(DEFAULT_HVC_CONFIG);

  useEffect(() => {
    const fetchDataFromServerAndUpdateState = async () => {
      try {
        const resp = await getProject(project_id);
        resp["hvc_config"] && setHVCConfig(resp["hvc_config"]);
      } catch (error) {
        showDangerMessage(`Failed due to :  ${error}`);
      }
    };

    if (project_id) {
      fetchDataFromServerAndUpdateState();
    }
  }, [project_id]);

  const location = useLocation();
  const history = useHistory();

  const closeDrawer = () => {
    setIsVisible(false);
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("hvc_config") === "open") {
      urlParams.delete("hvc_config");
      history.replace({ search: urlParams.toString() });
    }
  };

  const onFinish = async () => {
    try {
      await saveHVCConfiguration(project_id, { hvc_config: hvcConfig });
      showSuccessMessage(`High Value Connections are updated successfully.`);
      setBtndisabled(true);
      closeDrawer();
    } catch (error) {
      alert(error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    showDangerMessage(`Failed due to :  ${errorInfo}`);
  };
  const onValuesChange = (targetItem) => {
    setHVCConfig({ ...hvcConfig, [targetItem.name]: targetItem.checked });
    setBtndisabled(false);
  };

  return (
    <Drawer
      title={`High Value Connections Configuration for Project (id: ${project_id})`}
      onClose={closeDrawer}
      visible={isVisible}
      width={600}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <Alert
        style={{ marginBottom: "20px" }}
        description="Make sure to save the desired configuration before adding decision makers to the project. HVC network blocs will be filtered according to this configuration at the time of adding."
        type="warning"
        closable
      />
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {Object.keys(HVC_CONFIG_TYPES).map((key, idx) => (
          <Row style={{ paddingBottom: 5 }} key={idx}>
            <Col>
              <input
                type="checkbox"
                id={key}
                key={key}
                value={key}
                name={key}
                checked={hvcConfig[key]}
                onChange={(e) => onValuesChange(e.target)}
              />
              <label style={{ margin: 10 }}>{HVC_CONFIG_TYPES[key]}</label>
            </Col>
          </Row>
        ))}
        <Row
          style={{
            paddingBottom: 0,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={btnDisabled}>
                Save Configuration
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default HVCConfigDrawer;
