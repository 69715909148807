import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { isUserAllowedToReachGivenPage } from "../../util/feature_flags/users";
import { actionCreators } from "../../store";
import { AccountSelector } from "../AccountSelector/AccountSelector";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Space,
  Divider,
  Tooltip,
  Skeleton,
  Tag,
} from "antd";
import {
  AreaChartOutlined,
  LogoutOutlined,
  EditOutlined,
  LockOutlined,
  HomeOutlined,
  DollarOutlined,
  TeamOutlined,
  CodeSandboxOutlined,
  CloudUploadOutlined,
  UnorderedListOutlined,
  UserOutlined,
  SubnodeOutlined,
} from "@ant-design/icons";
import "./Navbar.css";
import { useLocation } from "react-router-dom";

const { Content, Header, Sider } = Layout;
const { SubMenu } = Menu;

const isBeta = process.env.REACT_APP_IS_BETA === "True";
const betaBGColor = "#1c66e2";
const bannerFGColor = "#fff";

const UserSubMenu = ({ theme }) => {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { userLogoutAction } = bindActionCreators(actionCreators, dispatch);

  const userInitials = !!user?.full_name
    ? user.full_name
        .split(" ")
        .map((t) => t[0])
        .join("")
        .toUpperCase()
    : "?";

  return (
    <Row wrap={false}>
      <Col>
        <Tag
          icon={<UserOutlined />}
          style={
            isBeta
              ? { backgroundColor: "#6b9bec", borderColor: "#6b9bec" }
              : { backgroundColor: "#fff", borderColor: "#6b9bec" }
          }
        >
          {userInitials}
        </Tag>
      </Col>
      <Col>
        <Tooltip title={"Logout"}>
          <Button
            type="primary"
            shape="circle"
            icon={<LogoutOutlined />}
            onClick={() => {
              userLogoutAction(true);
            }}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

const LandingPageHeader = () => {
  const bannerBGColor = isBeta ? betaBGColor : "#fff";
  const bannerFGColor = isBeta ? "#fff" : "#000";

  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: bannerBGColor,
        padding: "0 80px",
      }}
    >
      <Row>
        <Col>
          <Row style={{ alignItems: "baseline" }} gutter={6}>
            <Col>
              <img
                src={isBeta ? "/white-logo512.png" : "/blue-logo512.png"}
                alt="logo"
                width="40"
                height="40"
                style={{ marginBottom: "0.6em" }}
              />
            </Col>
            <Col>
              <h1 style={{ color: bannerFGColor }}>
                <b>Applecart</b>
              </h1>
            </Col>
            {isBeta && (
              <Col>
                <h3
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    color: bannerFGColor,
                  }}
                >
                  [BETA]
                </h3>
              </Col>
            )}
          </Row>
        </Col>
        <Col style={{ marginLeft: "20px" }} span={6}>
          <AccountSelector />
        </Col>
        <Col style={{ marginLeft: "auto" }}>
          <UserSubMenu theme="light" />
        </Col>
      </Row>
    </Header>
  );
};

const HeaderWithBackToProjectsButton = () => {
  const bannerBGColor = isBeta ? betaBGColor : "#000";

  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: bannerBGColor,
        padding: "0 40px",
      }}
    >
      <Row>
        <Col style={{ marginLeft: "auto" }}>
          <UserSubMenu theme="light" />
        </Col>
      </Row>
    </Header>
  );
};
const ProjectPageHeader = ({ setShowProjectDrawer }) => {
  const bannerBGColor = isBeta ? betaBGColor : "#000";

  const project = useSelector((state) => state.project);
  const isProjectLoading = useSelector((state) => state.isProjectLoading);
  const dispatch = useDispatch();

  const campaignNameDiffersFromProjectName =
    !!project?.campaign_object?.name &&
    project?.campaign_object?.name !== project?.name;
  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: bannerBGColor,
        padding: "0 40px",
        whiteSpace: "nowrap",
      }}
    >
      {isBeta && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            height: "25px",
            marginTop: "-20px",
          }}
        >
          <h4
            style={{ fontFamily: "Roboto, sans-serif", color: bannerFGColor }}
          >
            [BETA]
          </h4>
        </div>
      )}
      <Row justify="end">
        <Col span={16} style={{ textAlign: "center" }}>
          {isProjectLoading || !project ? (
            <Skeleton.Input
              active
              style={{ width: "80%", marginTop: "20px" }}
            />
          ) : (
            <Row
              style={{
                color: bannerFGColor,
                fontSize: "15px",
                fontWeight: 600,
              }}
              justify="center"
              wrap={false}
            >
              {project?.status === "COMPLETED" && (
                <Tooltip title="This project is marked as 'Completed' and cannot be edited.">
                  <LockOutlined />
                  &nbsp;&nbsp;
                </Tooltip>
              )}
              {campaignNameDiffersFromProjectName && (
                <>
                  <Col
                    style={{
                      maxWidth: "15vw",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {project.campaign_object.name}{" "}
                  </Col>
                  <Col>
                    <Divider
                      style={{ borderColor: bannerFGColor }}
                      type="vertical"
                    />
                  </Col>
                </>
              )}
              <Col
                style={{
                  maxWidth: "30vw",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {project?.name}
              </Col>
              <Col>
                <Divider
                  style={{ borderColor: bannerFGColor }}
                  type="vertical"
                />
              </Col>
              <Col
                style={{
                  maxWidth: "30vw",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Client: {project?.client_name}
              </Col>
            </Row>
          )}
        </Col>
        <Col span={6} style={{ textAlign: "right", overflow: "auto" }}>
          <Row justify="end" wrap={false}>
            <Space size="large">
              <div>
                <Tooltip title={"Export"}>
                  <Button
                    style={{
                      margin: "0 10px",
                    }}
                    icon={
                      <CloudUploadOutlined
                        style={{
                          color: "white",
                          fontSize: "24px",
                        }}
                      />
                    }
                    onClick={() =>
                      dispatch({
                        type: "set-is-open-export-ui-drawer",
                        payload: true,
                      })
                    }
                    type="text"
                  />
                </Tooltip>
                <Tooltip title="Edit project details">
                  <Button
                    icon={
                      <EditOutlined
                        style={{ color: "white", fontSize: "24px" }}
                      />
                    }
                    onClick={() =>
                      dispatch({
                        type: "set-is-open-project-drawer",
                        payload: true,
                      })
                    }
                    type="text"
                  />
                </Tooltip>
              </div>
              <UserSubMenu theme="dark" />
            </Space>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

const GetHeader = () => {
  const location = useLocation();
  const pageName = location.pathname;
  if (pageName.includes("/projects/")) {
    return ProjectPageHeader;
  } else if (
    pageName.includes("package") ||
    pageName.includes("decision-makers") ||
    pageName.includes("inex-lists") ||
    pageName.includes("staffers") ||
    pageName.includes("company-groups")
  ) {
    return HeaderWithBackToProjectsButton;
  } else {
    return LandingPageHeader;
  }
};

const Navbar = ({ children, contentClass = "project-page-content" }) => {
  const userGroups = useSelector((state) => state.userGroups);

  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState([location.pathname]);

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedKey("/projects");
    } else if (location.pathname.includes("/projects/")) {
      setSelectedKey("/projects");
    }
  }, [location.pathname]);

  const ABBAHeader = GetHeader();

  return (
    <Layout className="layout" style={{ minHeight: "100vh" }} theme="light">
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0,
        }}
        collapsible
        trigger={null}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        onMouseOver={() => {
          setCollapsed(false);
        }}
        onMouseOut={() => {
          setCollapsed(true);
        }}
      >
        <Menu theme="dark" mode="vertical" selectedKeys={selectedKey}>
          {isUserAllowedToReachGivenPage(userGroups, "projects") && (
            <Menu.Item
              key="/projects"
              icon={<HomeOutlined />}
              onClick={() => {
                history.push(`/projects`);
              }}
            >
              Projects
            </Menu.Item>
          )}
          {(isUserAllowedToReachGivenPage(userGroups, "active_projects") ||
            isUserAllowedToReachGivenPage(userGroups, "account_summary") ||
            isUserAllowedToReachGivenPage(userGroups, "destinations")) && (
            <SubMenu
              key="accounts"
              title="Account"
              icon={<AreaChartOutlined />}
            >
              {isUserAllowedToReachGivenPage(userGroups, "active_projects") && (
                <Menu.Item
                  key="/account-projects"
                  onClick={() => {
                    history.push(`/account-projects`);
                  }}
                >
                  Active Projects
                </Menu.Item>
              )}
              {isUserAllowedToReachGivenPage(userGroups, "account_summary") && (
                <Menu.Item
                  key="/account-dashboard"
                  onClick={() => {
                    history.push(`/account-dashboard`);
                  }}
                >
                  Summary
                </Menu.Item>
              )}
              {isUserAllowedToReachGivenPage(userGroups, "destinations") && (
                <Menu.Item
                  key="/destination"
                  onClick={() => {
                    history.push(`/destination`);
                  }}
                >
                  Destinations
                </Menu.Item>
              )}
              {isUserAllowedToReachGivenPage(userGroups, "campaign") && (
                <Menu.Item
                  key="/account-campaigns"
                  onClick={() => {
                    history.push(`/account-campaigns`);
                  }}
                >
                  Campaigns
                </Menu.Item>
              )}
            </SubMenu>
          )}

          {isUserAllowedToReachGivenPage(userGroups, "decision_makers") && (
            <SubMenu key="dm" title="Decision Makers" icon={<TeamOutlined />}>
              <Menu.Item
                key="/decision-makers"
                onClick={() => {
                  history.push(`/decision-makers`);
                }}
              >
                Search
              </Menu.Item>
              <Menu.Item
                key="/decision-makers-grid"
                onClick={() => {
                  history.push(`/decision-makers-grid`);
                }}
              >
                Grid
              </Menu.Item>
            </SubMenu>
          )}
          {isUserAllowedToReachGivenPage(userGroups, "enrichment") && (
            <SubMenu key="enrich" title="Enrichment" icon={<SubnodeOutlined />}>
              <Menu.Item
                key="/staffers"
                onClick={() => {
                  history.push(`/staffers`);
                }}
              >
                Staffers
              </Menu.Item>
              <Menu.Item
                key="/company-groups"
                onClick={() => {
                  history.push(`/company-groups`);
                }}
              >
                Company Groups
              </Menu.Item>
            </SubMenu>
          )}

          {isUserAllowedToReachGivenPage(userGroups, "packages") && (
            <Menu.Item
              key="/packages"
              icon={<CodeSandboxOutlined />}
              onClick={() => {
                history.push(`/packages`);
              }}
            >
              Packages
            </Menu.Item>
          )}
          {isUserAllowedToReachGivenPage(userGroups, "in_ex_lists") && (
            <Menu.Item
              key="/inex-lists"
              icon={<UnorderedListOutlined />}
              onClick={() => {
                history.push(`/inex-lists`);
              }}
            >
              InEx Lists
            </Menu.Item>
          )}
          {isUserAllowedToReachGivenPage(userGroups, "unit_tracking") && (
            <SubMenu key="admin" title="Admin" icon={<DollarOutlined />}>
              <Menu.Item
                key="/admin"
                onClick={() => {
                  history.push(`/admin`);
                }}
              >
                Usage
              </Menu.Item>
              <Menu.Item
                key="/account"
                onClick={() => {
                  history.push(`/account`);
                }}
              >
                Accounts
              </Menu.Item>
              <Menu.Item
                key="/permissions"
                onClick={() => {
                  history.push(`/permissions`);
                }}
              >
                Permissions
              </Menu.Item>
              <Menu.Item
                key="/account-membership"
                onClick={() => {
                  history.push(`/account-membership`);
                }}
              >
                Account Membership
              </Menu.Item>
            </SubMenu>
          )}
        </Menu>
      </Sider>
      <Layout>
        <ABBAHeader />
        <Content className={contentClass}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default Navbar;
