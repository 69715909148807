import React, { useEffect, useState } from "react";
import { Tabs, Card, Button } from "antd";
import { DecisionMakerForm } from "./DecisionMakerForm";
import { QualityAssurance } from "./QualityAssurance/QualityAssurance";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  UserOutlined,
  AppstoreOutlined,
  DeleteOutlined,
  FormOutlined,
} from "@ant-design/icons";
import StaffersGrid from "../Staffers/StaffersGrid";
import { getStaffersForGrid } from "../../APIClient";
import { formatSaffersForGrid } from "../Staffers/utils";
import { DeleteDecisionMakerModal } from "./DeleteDecisionMakerModal";
import { DecisionMakerNotes } from "./DecisionMakerNotes/DecisionMakersNotes";

const TabTitle = ({ title, icon }) => {
  return (
    <span style={{ display: "flex", alignItems: "center", padding: 10 }}>
      {icon} {title}
    </span>
  );
};

const DecisionMakersTabs = () => {
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const cardStyle = { borderTop: "none", minWidth: 900, maxWidth: "100vw" };
  const [staffers, setStaffers] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [, setRerender] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getStaffers = async () => {
      try {
        const data = await getStaffersForGrid(decisionMaker?.id);
        const staffersFormatted = formatSaffersForGrid(data);

        setStaffers(staffersFormatted);
      } catch (error) {
        alert(error.message);
      }
    };

    getStaffers();
  }, [decisionMaker]);

  return (
    <Tabs
      defaultActiveKey="decision_maker_info"
      tabBarStyle={{ marginBottom: 0 }}
      type="card"
      size="large"
    >
      <Tabs.TabPane
        type="card"
        key={"decision_maker_info"}
        tab={<TabTitle icon={<UserOutlined />} title="Decision Maker Info" />}
      >
        <Card style={cardStyle}>
          <>
            <Button
              style={{ float: "right", fontSize: "14px" }}
              danger
              type="link"
              onClick={() => setIsVisible(true)}
              icon={<DeleteOutlined />}
            >
              Delete Decision Maker
            </Button>
            <DeleteDecisionMakerModal
              setIsVisible={setIsVisible}
              isVisible={isVisible}
              decisionMaker={decisionMaker}
              history={history}
            />
            <QualityAssurance />
            <DecisionMakerForm />
          </>
        </Card>
      </Tabs.TabPane>
      <Tabs.TabPane
        style={{ marginBottom: 0, paddingBottom: 0 }}
        key={"staffer_grid"}
        tab={<TabTitle icon={<AppstoreOutlined />} title="Staffers" />}
      >
        <Card style={cardStyle}>
          {staffers?.length > 0 ? (
            <StaffersGrid
              staffers={staffers}
              setStaffers={setStaffers}
              setRerender={setRerender}
            />
          ) : (
            <h3>Decision maker doesn't have staffers</h3>
          )}
        </Card>
      </Tabs.TabPane>
      <Tabs.TabPane
        //style={{ marginBottom: 0, paddingBottom: 0 }}
        key={"decision_maker_notes"}
        tab={<TabTitle icon={<FormOutlined />} title="Notes" />}
      >
        <Card style={cardStyle}>
          <DecisionMakerNotes />
        </Card>
      </Tabs.TabPane>
    </Tabs>
  );
};

export default DecisionMakersTabs;
