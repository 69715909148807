import { Tag, Tooltip } from "antd";
import { formatTimestamp } from "../../../utils";

export const formatDecisionMakerQAStatus = (status, is_review = false) => {
  let qa_or_review = is_review ? "review" : "QA";

  const statusMapping = {
    no_qa: {
      text: `No ${qa_or_review}`,
      color: "red",
      tooltip: `Please ${qa_or_review} and update the timestamp`,
    },
    outdated: {
      text: `Edited`,
      color: "gold",
      tooltip: `The decision maker profile information has been edited since the last ${qa_or_review}`,
    },
    edited: {
      text: `Edited`,
      color: "gold",
      tooltip: `The decision maker profile information has been edited since the last ${qa_or_review}`,
    },
    ready: {
      text: "Ready",
      color: "green",
      tooltip: "Ready to be added to the project",
    },
    building: {
      text: "Building",
      color: "magenta",
      tooltip:
        "Please do not add this decision maker to the project until it is ready",
    },
    failed: {
      text: "Build failed",
      color: "error",
      tooltip: "Build for this decision maker failed, please contact support",
    },
    expired: {
      text: "Expired",
      color: "volcano",
      tooltip: `The last ${qa_or_review} had been done too long ago and now has expired`,
    },
  };
  if (status) {
    return (
      <Tooltip title={statusMapping[status].tooltip}>
        <Tag
          color={statusMapping[status].color}
          style={{
            alignContent: "center",
          }}
        >
          {statusMapping[status].text}
        </Tag>
      </Tooltip>
    );
  }
};

export const formatDecisionMakerNSBuildingStatus = (
  is_building = false,
  is_failed = false
) => {
  if (is_building) {
    return formatDecisionMakerQAStatus("building");
  } else if (is_failed) {
    return formatDecisionMakerQAStatus("failed");
  } else return false;
};

export const determineComprehensiveReviewStatus = (qa_events) => {
  let review_status;
  if (!qa_events?.review) {
    review_status = "no_qa";
  } else if (qa_events?.review?.is_expired) {
    review_status = "expired";
  } else if (
    new Date(qa_events.review.event_timestamp) <
    new Date(qa_events?.edit?.event_timestamp)
  ) {
    review_status = "edited";
  } else {
    review_status = "ready";
  }
  return {
    ...qa_events?.review,
    event_timestamp: formatTimestamp(qa_events?.review?.event_timestamp),
    review_status,
  };
};

export const determineQualityAssuranceStatus = (qa_events) => {
  let qa_status;
  if (qa_events?.qa?.is_expired) {
    qa_status = "expired";
  } else {
    qa_status = qa_events.qa_status;
  }
  return {
    ...qa_events?.qa,
    event_timestamp: formatTimestamp(qa_events?.qa?.event_timestamp),
    qa_status,
  };
};
