import { Form, Input, Row, Select, Col, DatePicker, Checkbox } from "antd";
import { useState } from "react";
import moment from "moment";
import { states } from "../../constants";

import { ExperienceCompanySelector } from "./ExperienceCompanySelector";

export const ExperienceForm = ({
  disabled = false,
  companyName,
  setCompanyName,
  setCompanyId,
  city,
  setCity,
  state,
  setState,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  isEditExperience = false,
  isAssociation,
  setIsAssociation,
  employeeCount = null,
  location = null,
  isGroup,
}) => {
  const { RangePicker } = DatePicker;
  const [loading, setloading] = useState(false);

  const setCompanyInfo = (companyInfoString) => {
    const companyInfoObject = JSON.parse(companyInfoString);
    const { company_name, company_id } = companyInfoObject;
    setCompanyName(company_name);
    setCompanyId(company_id);
  };

  const setDates = (dates) => {
    setFromDate(
      !dates?.[0] ? null : moment(String(dates[0].year()) + "-01-01")
    );
    setToDate(!dates?.[1] ? null : moment(String(dates[1].year()) + "-12-31"));
  };

  return (
    <Form>
      <Row gutter={0}>
        <Col>
          <Form.Item>
            <ExperienceCompanySelector
              setCompanyInfo={setCompanyInfo}
              loading={loading}
              setloading={setloading}
              isGroup={isGroup}
              isEditExperience={isEditExperience}
              companyName={companyName}
              employeeCount={employeeCount}
              location={location}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item style={{ display: "inline-block", margin: "0 20px 20px" }}>
            <Checkbox
              disabled={disabled}
              checked={isAssociation}
              onChange={(e) => {
                setIsAssociation(e.target.checked);
              }}
            >
              Association
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col>
          <Form.Item>
            <Input
              style={{ width: "220px" }}
              disabled={disabled}
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <Select
              showSearch
              allowClear
              disabled={disabled}
              style={{ width: "220px" }}
              placeholder="State"
              value={state}
              onChange={(val) => setState(val)}
            >
              {states.map((state) => (
                <Select.Option key={state} value={state.toLocaleLowerCase()}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item>
          <RangePicker
            disabled={disabled}
            value={[
              fromDate ? moment(fromDate, "YYYY") : undefined,
              toDate ? moment(toDate, "YYYY") : undefined,
            ]}
            onChange={(dates) => setDates(dates)}
            placeholder={["From Date", "To Date"]}
            format={"YYYY"}
            style={{ width: "450px" }}
            picker="year"
            allowEmpty={[false, true]}
          />
        </Form.Item>
      </Row>
    </Form>
  );
};
