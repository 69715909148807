import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Popconfirm, Row, Space, Spin } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  createDecisionMakerExperience,
  deleteDecisionMakerExperience,
  getDecisionMakerExperiences,
  updateDecisionMakerExperience,
  getEASCompanies,
} from "../../APIClient";
import { checkFormChanged, showSuccessMessage } from "../../utils";
import { ExperienceForm } from "./ExperienceForm";
import { DecisionMakerContext } from "./DecisionMakerContext";

export const Experiences = () => {
  // local state
  const [isLoading, setIsLoading] = useState(false);
  const [creatingNew, setCreatingNew] = useState(false);
  const [experiences, setExperiences] = useState([]);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);

  const fetchExperiences = async () => {
    // turn on loading
    setIsLoading(true);
    try {
      const data = await getDecisionMakerExperiences(decisionMaker.id);
      const company_ids = data.map((exp) => exp.company_id);
      const companies = await getEASCompanies(company_ids);
      const getCompanyInfo = (company_id) => {
        let desiredCompany = companies.filter(
          (x) => x.company_id === company_id
        )[0];
        return {
          employee_count: desiredCompany?.employee_count,
          location:
            desiredCompany.city && desiredCompany.state
              ? `${desiredCompany?.city}-${desiredCompany?.state}`
              : desiredCompany?.city
              ? desiredCompany?.city
              : desiredCompany?.state,
          company_name: desiredCompany?.company_name,
          is_group: desiredCompany?.is_group,
        };
      };
      setExperiences(
        data.map((c) => {
          return {
            ...c,
            ...getCompanyInfo(c.company_id),
          };
        })
      );
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (decisionMaker.id) {
      fetchExperiences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id, decisionMaker.eas_profile_id]);

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {creatingNew ? (
          <CreateExperience
            onCancel={() => setCreatingNew(false)}
            callback={() => {
              setCreatingNew(false);
              fetchExperiences();
            }}
            setIsLoading={setIsLoading}
          />
        ) : (
          <Row>
            <Button
              block
              type="link"
              onClick={() => setCreatingNew(true)}
              style={{ textAlign: "left" }}
              size="large"
            >
              <PlusOutlined /> Add another
            </Button>
          </Row>
        )}
        {experiences?.length > 0 && (
          <Divider plain orientation="left">
            Existing Experiences
          </Divider>
        )}
        {experiences?.map((e, i) => (
          <React.Fragment key={i}>
            {i !== 0 && <Divider />}
            <EditExperiences
              experienceValues={e}
              callback={() => {
                fetchExperiences();
              }}
              setIsLoading={setIsLoading}
            />
          </React.Fragment>
        ))}
      </Space>
    </Spin>
  );
};

export const CreateExperience = ({ onCancel, callback, setIsLoading }) => {
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [isAssociation, setIsAssociation] = useState(false);

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const onSave = async () => {
    setIsLoading(true);
    try {
      await createDecisionMakerExperience(decisionMaker.id, {
        companyId,
        companyName,
        fromDate: fromDate?.format("YYYY-MM-DD"),
        toDate: toDate?.format("YYYY-MM-DD"),
        city,
        state,
        isAssociation,
      });
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("New Experience added.");
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const resetForm = () => {
    setFromDate(undefined);
    setToDate(undefined);
    setCompanyId();
    setCompanyName();
    setCity();
    setState();
    setIsAssociation(false);
  };

  useEffect(() => {
    resetForm();
  }, [decisionMaker.id]);

  return (
    <Row>
      <Col span={16}>
        <ExperienceForm
          companyName={companyName}
          setCompanyId={setCompanyId}
          setCompanyName={setCompanyName}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          isAssociation={isAssociation}
          setIsAssociation={setIsAssociation}
        />
      </Col>
      <Col span={8}>
        <Space size={5}>
          <Button onClick={onCancel} size="middle" style={{ width: "75px" }}>
            Cancel
          </Button>
          <Button
            onClick={onSave}
            type="primary"
            disabled={!companyName || !fromDate}
            size="middle"
            style={{ width: "65px" }}
          >
            Save
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export const EditExperiences = ({
  experienceValues,
  callback,
  setIsLoading,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [isAssociation, setIsAssociation] = useState();
  const [employeeCount, setEmployeeCount] = useState();
  const [location, setLocation] = useState();
  const [isGroup, setIsGroup] = useState();

  // redux state
  const decisionMaker = useSelector((state) => state.decisionMaker);

  const { setUpdateDecisionMakerQAStatus } = useContext(DecisionMakerContext);

  const resetForm = () => {
    setCompanyId(experienceValues?.company_id);
    setCompanyName(experienceValues?.company_name);
    setCity(experienceValues?.city);
    setState(experienceValues?.state);
    setFromDate(
      experienceValues?.from_date ? moment(experienceValues?.from_date) : null
    );
    setToDate(
      experienceValues?.to_date ? moment(experienceValues?.to_date) : null
    );
    setEmployeeCount(experienceValues?.employee_count);
    setIsAssociation(experienceValues?.is_association);
    setLocation(experienceValues?.location);
    setIsGroup(experienceValues?.is_group);
  };

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceValues]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      await updateDecisionMakerExperience(
        decisionMaker.id,
        experienceValues.id,
        {
          companyId,
          companyName: companyName.replace(` (${employeeCount})`, "").trim(),
          fromDate: fromDate?.format("YYYY-MM-DD"),
          toDate: toDate?.format("YYYY-MM-DD"),
          city,
          state,
          isAssociation,
        }
      );
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Experience updated.");
    } catch (error) {
      alert(error);
      resetForm();
    }
    setIsEditing(false);
    setIsLoading(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await deleteDecisionMakerExperience(
        decisionMaker.id,
        experienceValues.id
      );
      callback();
      setUpdateDecisionMakerQAStatus(true);
      showSuccessMessage("Experience deleted.");
    } catch (error) {
      console.log(error);
      alert(error);
    }
    setIsLoading(false);
  };

  return (
    <Row>
      <Col span={16}>
        <ExperienceForm
          companyName={companyName}
          setCompanyName={setCompanyName}
          setCompanyId={setCompanyId}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          disabled={!isEditing}
          isEditExperience={true}
          isAssociation={isAssociation}
          setIsAssociation={setIsAssociation}
          employeeCount={employeeCount}
          location={location}
          isGroup={isGroup}
        />
      </Col>
      <Col span={7}>
        {isEditing ? (
          <Space size={5}>
            <Button
              onClick={() => {
                setIsEditing(false);
                resetForm();
              }}
              size="middle"
              style={{ width: "75px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={onSave}
              type="primary"
              disabled={
                !fromDate ||
                !checkFormChanged(experienceValues, {
                  city: city,
                  state: state,
                  is_association: isAssociation,
                  from_date: fromDate?.format("YYYY-MM-DD"),
                  to_date: toDate?.format("YYYY-MM-DD"),
                })
              }
              size="middle"
              style={{ width: "65px" }}
            >
              Save
            </Button>
          </Space>
        ) : (
          <Button
            onClick={() => setIsEditing(true)}
            size="middle"
            style={{ width: "140px" }}
          >
            Edit
          </Button>
        )}
      </Col>
      <Col span={1}>
        <Popconfirm
          title={`Delete Experience?`}
          okText="Yes"
          onConfirm={onDelete}
        >
          <Button danger type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Col>
    </Row>
  );
};
