import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Affix,
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addTargets,
  createAutomatedNetworkBlocs,
  findDecisionMaker,
  getDMNetworkBuildingStatus,
} from "../../APIClient";
import { isObjectEmpty, removeKeyFromObject } from "../../utils";
import {
  formatDecisionMakerQAStatus,
  formatDecisionMakerNSBuildingStatus,
} from "../DecisionMakers/QualityAssurance/DecisionMakerStatus";

const DecisionMakerMatchCard = ({ decisionMaker, onClick }) => (
  <Card
    title={
      <Row style={{ justifyContent: "space-between" }}>
        {formatDecisionMakerNSBuildingStatus(
          decisionMaker?.is_building,
          decisionMaker?.is_failed
        ) ||
          formatDecisionMakerQAStatus(
            decisionMaker?.qa_events?.qa?.is_expired ? "expired" : false
          ) ||
          formatDecisionMakerQAStatus(decisionMaker?.qa_status)}
        <Button type="link" onClick={onClick}>
          Use this Decision Maker <CopyOutlined />
        </Button>
      </Row>
    }
  >
    <Descriptions column={2}>
      <Descriptions.Item label={<b>First Name</b>}>
        {decisionMaker.first_name}
      </Descriptions.Item>
      <Descriptions.Item label={<b>Last Name</b>}>
        {decisionMaker.last_name}
      </Descriptions.Item>
      <Descriptions.Item label={<b>Type</b>}>
        {decisionMaker.decision_maker_type}
      </Descriptions.Item>
    </Descriptions>
  </Card>
);

const CreateSingleTarget = ({ setIsVisible, setIsDMSModalVisible }) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [searchFirstName, setSearchFirstName] = useState();
  const [searchLastName, setSearchLastName] = useState();
  const [decisionMakerMatches, setDecisionMakerMatches] = useState([]);
  const [targetsToCreate, setTargetsToCreate] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const onSearch = async () => {
    try {
      setIsLoading(true);
      const dm_data = await findDecisionMaker(searchFirstName, searchLastName);
      if (dm_data) {
        const dm_status_data = await getDMNetworkBuildingStatus(
          dm_data.map((item) => item.id)
        );
        const combinedData = dm_data.map((dm_data_item) => ({
          key: dm_data_item.id,
          ...dm_data_item,
          ...dm_status_data.find(
            (dm_status_item) => dm_status_item.dm_id === dm_data_item.id
          ),
          dm_network_size: "STANDARD",
        }));
        setDecisionMakerMatches(combinedData);
      }
    } catch (e) {
      alert(e);
    }
    setIsLoading(false);
  };

  const createTargets = async () => {
    setIsLoading(true);
    try {
      const res = await addTargets(
        projectId,
        Object.values(targetsToCreate).map((t) => ({
          dm_id: t.dm_id,
          dm_network_size: t.dm_network_size,
        }))
      );
      setIsVisible(false);
      setTargetsToCreate({});
      message.success("Decision Maker successfully added");
      dispatch({ type: "set-audience-breakdown-outdated" });
      setCurrentStep(0);
      setSearchFirstName();
      setSearchLastName();
      setDecisionMakerMatches([]);
      await createAutomatedNetworkBlocs(
        projectId,
        res.map(({ dm_id, id }) => {
          return {
            dm_id,
            tpl_id: id,
            project_id: projectId,
            dm_network_size: targetsToCreate[dm_id]?.dm_network_size,
          };
        })
      );
    } catch (e) {
      alert(e);
    }
    setIsLoading(false);
  };

  const stepContent = [
    <div>
      <Form layout="vertical">
        <Form.Item label="First name">
          <Input
            onChange={(e) => setSearchFirstName(e.target.value)}
            value={searchFirstName}
          />
        </Form.Item>
        <Form.Item label="Last name">
          <Input
            onChange={(e) => setSearchLastName(e.target.value)}
            value={searchLastName}
          />
        </Form.Item>
        <Button
          onClick={onSearch}
          type="primary"
          disabled={!(searchFirstName || searchLastName)}
          loading={isLoading}
        >
          Search
        </Button>
      </Form>
      <Spin spinning={isLoading}>
        <Space
          size="middle"
          direction="vertical"
          style={{ width: "100%", margin: "20px auto" }}
        >
          <Row style={{ justifyContent: "center" }}>
            Don't see what you're looking for? Click below to create a new
            Decision Maker.
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Button
              shape="round"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsDMSModalVisible(true)}
            >
              Create Decision Maker
            </Button>
          </Row>
        </Space>
        {decisionMakerMatches.length > 0 ? (
          decisionMakerMatches.map((row) => (
            <DecisionMakerMatchCard
              key={row.key}
              decisionMaker={row}
              onClick={() => {
                setTargetsToCreate({
                  ...targetsToCreate,
                  [row.id]: row,
                });
                setCurrentStep(1);
              }}
            />
          ))
        ) : (
          <Empty />
        )}
      </Spin>
    </div>,
    <>
      {Object.values(targetsToCreate)
        .sort((firstTarget, secondTarget) =>
          firstTarget.first_name.localeCompare(secondTarget.first_name)
        )
        .map((t) => (
          <Tag
            key={t.key}
            style={{ padding: "5px 20px", width: "100%", margin: "auto" }}
          >
            <Row justify="space-between" style={{ alignItems: "baseline" }}>
              <Col style={{ fontSize: "1.25em" }}>
                {`${t.first_name} ${t.last_name}`}
              </Col>
              <Col>
                <Space>
                  {
                    <Tooltip title={`Please select network size`}>
                      <Select
                        onSelect={(value) => {
                          if (targetsToCreate[t.key]) {
                            const newCreatedTargets = {
                              ...targetsToCreate,
                              [t.key]: { ...t, dm_network_size: value },
                            };
                            setTargetsToCreate(newCreatedTargets);
                          }
                        }}
                        value={t.dm_network_size}
                        bordered={true}
                        size="small"
                        showArrow={false}
                        dropdownMatchSelectWidth={false}
                        style={{
                          color: "gray",
                          fontSize: "12px",
                        }}
                        options={[
                          {
                            value: "NARROW",
                            label: "Narrow",
                          },
                          {
                            value: "STANDARD",
                            label: "Standard",
                          },
                          {
                            value: "EXPANDED",
                            label: "Expanded",
                          },
                        ]}
                      />
                    </Tooltip>
                  }
                  <Button
                    type="text"
                    onClick={() => {
                      const newCreatedTargets = removeKeyFromObject(
                        targetsToCreate,
                        t.id
                      );
                      setTargetsToCreate(newCreatedTargets);
                      if (isObjectEmpty(newCreatedTargets)) {
                        setCurrentStep(0);
                      }
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </Space>
              </Col>
            </Row>
          </Tag>
        ))}
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setCurrentStep(0)}
      >
        Add Decision Maker
      </Button>
      <Affix
        offsetBottom={0}
        style={{
          textAlign: "right",
          position: "absolute",
          bottom: "40px",
          right: "20px",
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            createTargets();
          }}
        >
          Finish
        </Button>
      </Affix>
    </>,
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Steps size="small" current={currentStep} status="process">
        <Steps.Step key="1" title="Search Decision Makers" />
        <Steps.Step key="2" title="Finalize" />
      </Steps>
      {stepContent[currentStep]}
    </Space>
  );
};

const CreateTargetDrawer = ({
  isVisible,
  setIsVisible,
  graphVersion,
  refreshTargets,
  setIsDMSModalVisible,
}) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if (!isVisible) {
      refreshTargets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <Drawer
      title="Add Decision Maker"
      onClose={() => {
        setIsVisible(false);
        refreshTargets();
      }}
      visible={isVisible}
      width={600}
      bodyStyle={{ paddingBottom: 20 }}
    >
      <CreateSingleTarget
        graphVersion={graphVersion}
        isVisible={isVisible}
        setIsDMSModalVisible={setIsDMSModalVisible}
        setIsVisible={setIsVisible}
      />
    </Drawer>
  );
};

export default CreateTargetDrawer;
