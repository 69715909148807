import { http } from "../setTokenInterceptor";

export const getDecisionMakerTypeOptions = async () => {
  const response = await http.get(
    `/audience-builder/api/constants/decision-maker-types/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};

export async function getPackageById(packageId) {
  const response = await http.get(
    `/audience-builder/api/package/${packageId}/`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
}

export async function createPackage(params) {
  const response = await http.post(`/audience-builder/api/package/`, params);
  if (response.status === 201) {
    return response.data;
  } else {
    return response.data;
  }
}

export async function updatePackage(packageId, params) {
  const response = await http.patch(
    `/audience-builder/api/package/${packageId}/`,
    params
  );
  if (response.status === 200) {
    return true;
  } else {
    return response.data;
  }
}

export const findPackagesByName = async (packageName) => {
  const response = await http.get(
    `/audience-builder/api/package/find-existing-packages/`,
    { params: { name: packageName } }
  );
  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response.data);
    throw new Error(JSON.stringify(response.data));
  }
};
